#dest-title-h1 {
  font-family: sans-serif;
  background-position: center;
  text-align: center;
  padding: 100px 0;
  background-color: black;
}

#dest-title-h1 h1{
  color: rgb(177, 243, 180);
  font-size: 35px;
}


.break-line {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  box-shadow: 0px 0px 20px 9px rgb(255 255 255 / 25%);
}
.dest-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: sans-serif;
}

.sidebar {
  width: 40%;
  margin-top: 10px;
}

.dest-content {
  width: 80%;
  white-space: pre-line;
  padding: 0 20px;
}

.sidebar h2 {
  font-size: 25px;
}

.dest-container h2 {
  text-align: center;
  font-size: 25px;
  color: rgb(177, 243, 180);
}

.dest-content p {
  font-size: 20px;
  padding-right: 10px;
}

.sidebar p{
  font-size: 20px;
}

.sidebar span{
  font-size: 20px;
}

.sidebar-right {
  visibility: hidden;
}

.sidebar-left,
.sidebar-right {
  padding-right: 20px;
  padding-left: 20px;
}

.sidebar ul {
  list-style-type: none;
  justify-content: space-around;
  /* Remove default padding from major browsers */
  padding: 0;
}

.sidebar li {
  text-align: center;
}

.loading-placeholder p{
  font-size: 30px;
}

#dest-info-map {
  padding-top: 10%;
  height: 400px;
}

#best-time-visit{
  max-height:  30vh;
  margin:  0;
  padding:  0;
  display:  flex;
  flex-direction:  column;
  overflow: auto;
}

#things-to-do{max-height:  50vh;
  margin:  0;
  padding:  0;
  display:  flex;
  flex-direction:  column;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  #dest-title-h1 h1{
  font-size: 30px;
  }

 .sidebar-left, .sidebar-right {
  padding-right: 0px;
  padding-left: 0px;
  }

  .sidebar {
    width: 45%;
  }

  .dest-content {
    width: 60%
  }

  #dest-info-map {
    height: 500px;
  }

  .sidebar h2 {
    font-size: 20px;
  }

  .dest-container h2 {
    text-align: center;
    font-size: 20px;
  }

  .dest-content p {
    font-size: 15px;
  }

  .sidebar span{
    font-size: 15px;
  }

  .sidebar h2 {
    font-size: 20px;
  }

  .sidebar p {
    font-size: 15px;
  }

  .sidebar li {
    font-size: 15px;
  }

  #dest-title-h1 {
    padding: 25px 0;
  }

  .sidebar-left {
    padding-left: unset;
  }
}
