html,
body,
.App,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(30, 30, 30);
  color: rgb(255, 255, 255);
  font-size: 15px;
}

h1,
h4 {
  color: white;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  text-align: center;
  margin: 0;
}

.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  font-size: 5rem;
  text-align: center;
}
.scroller > span {
  position: relative;
  top: 0;
  animation-duration: 6s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-name: slide;
  font-weight: bold;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes slide {
  20%,
  23%,
  25% {
    top: 0;
  }
  45%,
  47%,
  50% {
    top: -1.2em;
  }
  70%,
  72%,
  75% {
    top: -2.4em;
  }
  95%,
  97%,
  100% {
    top: -3.6em;
  }
}

.container {
  overflow: auto;
  border: 1px solid rgb(97, 159, 209);
  padding: 30px;
  border-radius: 50px;
  padding-top: 30vh; /* adjust height value as needed */
  transition: padding-top 2s ease-out;
}

/* animation when search is submitted */
.container.search-submitted {
  padding-top: 5vh;
}

.region-form {
  background: #f4f4f4;
  margin: 10px;
  padding: 10px 10px;
  cursor: pointer;
}

.btn {
  background: rgb(30, 30, 30);
  color: #fff;
  border: 2px solid rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  margin-top: 20px;
}

.btn:hover {
  background-color: #282929;
  transition: 0.3s;
}

select {
  width: 150px !important;
  margin: 0px !important;
}

.attribute-collection {
  padding-left: 0px;
}

.attribute-slider {
  width: 200px;
  display: inline-block;
  padding-left: 1%;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 150px;
}

.input-form {
  border: 0.1rem solid #5f5f5f;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 3px 1px 8px #380e0e63;
}

form {
  text-align: center;
}

.form-control {
  margin: 20px 0;
}

.form-control input {
  width: fit-content;
  height: 30px;
  min-width: 400px;
  padding: 3px 7px;
  font-size: 17px;
  background: black;
  color: white;
  border: 1px solid;
  border-radius: 5px;
}

.form-checkbox {
  display: inline;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0.5cm;
  z-index: 0;
}

.map {
  padding-top: 1%;
  height: 600px;
  grid-column: 1;
}

#search-result-container {
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  align-items: center;
  grid-column: 2;
}

.leaflet-popup-content {
  margin: 0px 20px 0px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}

.leaflet-popup-content-wrapper {
  background-color: #57c672f2;
  color: rgb(30, 30, 30);
  border: 1px solid gray;
  border-radius: 26px;
  padding: 10px;
  opacity: 1;
}
.leaflet-popup-tip {
  background: #57c672f2;
  opacity: 0.9;
}

.grid-container {
  display: grid;
  grid-template-columns: 3fr 0.75fr;
  grid-gap: 20px;
}

.destination-box {
  width: 95%;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 254, 254, 1);
  box-shadow: 2px 2px 5px rgba(76, 79, 233, 0.336);
  text-align: left;
  background: rgb(50, 50, 50, 0.8);
  border-radius: 0.5cm;
  cursor: crosshair;
}

.destination-box.selected {
  border: 2px solid #57c672f2;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

input[type="number"] {
  border-radius: 50px;
  width: 35px;
  margin: 10px;
  text-align: center;
}

select {
  border-radius: 50px;
  margin: 10px;
  text-align: center;
  width: 100px;
}

.btn-ready-query {
  border-color: #44cc44;
  background: #23332d;
}

h4 > a[href]:hover {
  /* styling for hyper-links to destination names */
  color: green;
}

h4 > a[href] {
  /* styling for hyper-links to destination names */
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

.inputTempCheckBox {
  margin-top: 5px !important;
  width: fit-content;
  margin: auto;
  padding: 5px;
  background: black;
  border-radius: 10px;
  border: 1px solid white;
}

.showRailwayCheckBox {
  margin-top: 5px !important;
  width: fit-content;
  margin: auto;
  padding: 5px;
  background: black;
  border-radius: 10px;
  border: 1px solid white;
}

.dropdown-container {
  width: 100%;
}

.react-dropdown-container {
  min-width: 400px;
  width: fit-content;
  margin: 0 auto;
}

.submitBtn {
  margin: 0 auto;
  margin-top: 10px;
}

.NoDestinationDispNone {
  display: none;
}
.NoDestinationDispHidden {
  visibility: hidden;
}

#loc_hierarchy {
  display: block;
}

.regionInputAsync {
  width: 400px;
  margin: 0 auto;
}

.feedback-btn-wrapper {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 999;
}

.feedback-btn {
  /* Add your desired button styles here */
  background-color: #0a0a0a;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
}

.feedback-btn:hover {
  background-color: #282929;
  transition: 0.3s;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .feedback-btn {
    font-size: smaller;
  }
  .feedback-btn-wrapper {
    position: fixed;
    bottom: unset;
    top: 0px;
    right: 20px;
    z-index: 999;
  }

  .container {
    padding-top: 20vh; /* adjust height value as needed */
  }

  .react-dropdown-container {
    min-width: 200px;
  }

  h1 {
    font-size: 2.5rem;
  }
  .scroller {
    font-size: 3rem;
  }

  .attribute-collection {
    padding-bottom: 10px;
  }

  .attribute-slider {
    padding-top: 10px;
  }

  .grid-container {
    grid-template-columns: unset;
    margin-top: 10px;
    grid-gap: unset;
  }

  .map {
    padding-top: 5%;
    grid-row: 2;
    grid-column: unset;
    height: 450px;
  }

  #search-result-container {
    width: 330px;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    grid-row: 1;
    grid-column: unset;
    white-space: nowrap;
    padding-top: 10px;
  }

  .destination-box {
    width: fit-content; /* Example width value */
    display: inline-block;
    margin-bottom: unset;
    padding: unset;
    margin-right: 10px;
    height: auto;
    vertical-align: middle;
  }

  .form-control input {
    min-width: 300px;
    width: fit-content;
    font-size: small;
  }
  #react-select-3-placeholder {
    font-size: small;
  }
  /* Hide location details on small screens */
  #loc_hierarchy {
    display: none;
  }
  .regionInputAsync {
    width: 80%;
  }
}
