.feedback-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedback-container {
  background-color: black;
  padding: 10px;
  border-radius: 5%;
  width: 80%;
  max-width: 500px;
}

.feedback-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.feedback-buttons button {
  margin-left: 10px;
}

textarea {
  resize: none;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .feedback-container {
    max-width: 90%;
  }

  textarea {
    height: 100px;
    width: 90%;
  }
}
