.navbar {
    background-color: black;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 15px;
   }
  
 
  .links {
    list-style: none;
    display: flex;
    font-family: sans-serif;
  }
  
  .links a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
    font-size: 25px;
    text-underline-offset: 5px;
    text-decoration: underline;
  }
  
  .links a:hover {
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 767px) {
    .links a {
      font-size: 20px;
    }
  }